<template>
  <main>
  <div class="holder">
    <section id="deals">
      <!--<h1>{{ $t('deals.deals') }}</h1>-->

      <div class="cols">
        <div class="col">
          <nav>
            <ul class="fadeout js-fadeout" data-mobile-only="true" data-max-width="1000">
              <li :class="{'act': select === 'give'}" @click="give"><a>{{ $t('deals.give') }}</a></li>
              <li :class="{'act': select === 'take'}" @click="take"><a>{{ $t('deals.take') }}</a></li>
              <li :class="{'act': select === 'confirm'}" @click="confirm"><a>{{ $t('deals.confirm') }}</a></li>
            </ul>
          </nav>
        </div>

        <div class="col" ref="dealsList">
          <ul id="deals-list">
            <li v-for="item in deals" :key="item.id"
                :class="{'expired' : item.completed !== null}">
              <div class="info-holder">
                <a class="photo">
                  <span class="img-holder" @click="goToGoods(item.id)">
                    <img :src="createResourceUrl(item.favoriteResourceId)" :alt="item.title"
                         :title="item.title">
                  </span>
                  <span class="count" v-if="item.unreadMessagesCount">{{ item.unreadMessagesCount }}</span>
                </a>
                <div class="info">
                  <div class="author" v-if="select === 'take'">{{ item.owner.name }}</div>
                  <div class="author" v-if="select === 'confirm'">{{ item.deal.declarer.name }}</div>
                  <h2 class="title">
                    <a @click="goToGoods(item.id)">{{ item.title }}</a>
                  </h2>
                  <div
                          v-if="item.bookDate !== null && new Date(item.bookDate) > new Date()"
                          class="time-left" v-html="$t('deals.booked', { date: $options.filters.expireDateFilter(item.bookDate) })"></div>
                  <div
                      v-if="item.completed === null && item.expireDate !== null && new Date(item.expireDate) > new Date()"
                      class="time-left" v-html="$t('deals.left', { date: $options.filters.expireDateFilter(item.expireDate) })"></div>
                  <div v-else-if="item.completed" class="time-left"
                       v-html="$t('deals.closed', { date: $options.filters.expireDateFilter(item.completed) })">
                  </div>
                  <div v-else-if="item.expireDate" class="time-left"
                       v-html="$t('deals.expired', { date: $options.filters.expireDateFilter(item.expireDate) })">
                  </div>
                </div>
              </div>
              <div class="confirmation" v-if="select === 'confirm' && item.deal">
                <div class="message">
                  <div class="author">{{ item.deal.declarer.name }}, {{ item.deal.message.created | date }}</div>
                  <div class="text">{{ item.deal.message.text }}</div>
                </div>
                <button class="btn cancel" @click="onConfirmDealClicked(item.id, item.deal.chatId, false)">{{ $t('deals.cancel') }}
                </button>
                <button class="btn" @click="onConfirmDealClicked(item.id, item.deal.chatId, true)">{{ $t('deals.accept') }}</button>
              </div>
              <div class="selected" :class="{'act' : item.favorite}" @click="addToFavorite(item)"></div>
            </li>

            <infinite-loading :distance="infiniteScrollDistance" forceUseInfiniteWrapper="true" :identifier="infinite"
                              @infinite="loadDealsInfinite">
              <div slot="spinner">
                <loading-progress
                    :progress="0"
                    :indeterminate="true"
                    :counter-clockwise="false"
                    :hide-background="false"
                    shape="circle"
                    :size="loadingIconSize"
                />
              </div>
              <div slot="no-more"></div>
              <div slot="no-results">
                <div class="empty" v-if="select === 'take'">
                  <h2>{{ $t('deals.empty-take') }}</h2>
                  <p class="text">{{ $t('deals.empty-take-text') }}</p>
                </div>
                <div class="empty" v-else-if="select === 'give'">
                  <h2>{{ $t('deals.empty-give') }}</h2>
                  <p class="text">{{ $t('deals.empty-give-text') }}</p>
                </div>
                <div class="empty" v-else-if="select === 'confirm'">
                  <h2>{{ $t('deals.empty-confirm') }}</h2>
                  <p class="text">{{ $t('deals.empty-confirm-text') }}</p>
                </div>
              </div>
              <div slot="error"></div>
            </infinite-loading>
          </ul>

        </div>
      </div>
    </section>
  </div>
  </main>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import expireDateFilter from '../assets/filters/expireDateFilter.js';
  import InfiniteLoading from 'vue-infinite-loading';
  import fadeout from '../assets/js/init/fadeout';

  export default {
    name: 'Deals',
    title: 'Deals',
    filters: {
      expireDateFilter,
    },
    components: { InfiniteLoading },
    data() {
      return {
        infinite: +new Date(),
        infiniteScrollDistance: 600,
        loadingIconSize: 30,
      };
    },
    computed: {
      ...mapGetters({
        deals: 'deals',
        params: 'dealsParams',
        select: 'select'
      })
    },
    watch: {
      deals() {
        if (this.deals.length) {
          this.updateDealsLastId(this.deals[this.deals.length - 1].id);
        }
      }
    },
    created() {
    },
    mounted() {
      this.loaderIconInit();
      fadeout.fadeoutInit();
    },
    methods: {
      ...mapMutations(['addDeals', 'overwriteDeals', 'updateDealsLastId', 'setGive', 'setTake', 'setConfirm']),
      ...mapActions(['loadDeals', 'confirmDeal', 'addToFavorite']),

      loaderIconInit() {
        let that = this;
        this.loadingIconSize = this.$refs.dealsList.clientWidth / 15 ;
        window.addEventListener('resize', function () {
          that.loadingIconSize = that.$refs.dealsList.clientWidth / 15;
        });
      },
      async loadDealsInfinite($state) {
        const oldInfiniteId = this.infinite;
        let response = await this.loadDeals(this.params);
        if (oldInfiniteId !== this.infinite) return;

        if (response.data.length) {
          this.addDeals(response.data);
          $state.loaded();
        } else {
          $state.complete();
        }
      },
      take() {
        this.setTake();
        this.infinite++;
      },
      give() {
        this.setGive();
        this.infinite++;
      },
      confirm() {
        this.setConfirm();
        this.infinite++;
      },
      goToGoods(goodsId) {
        this.$router.push({
          name: 'goods',
          params: { id: goodsId }
        });
      },
      createResourceUrl(id) {
        if (id) {
          return appSettings.baseUrl + 'api/client/resource/' + id;
        } else {
          return null;
        }
      },
      onConfirmDealClicked(goodsId, chatId, accept) {
        this.confirmDeal({
          id: goodsId,
          body: {
            accept,
            chatId
          }
        });
      },
      addItemToFavorite(item, index){
        this.setFavoriteDeal({id: item.id});
        this.addToFavorite({
          id: item.id,
          favorite: !item.favorite,
          index: index,
        });
      },
    }
  };
</script>

<style scoped>

</style>
